import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "semantic-ui-react"
import { fetchCurrentCompany, fetchCurrentCompanyCredits } from "../../../../actions/company.actions";
import { advisorAPI } from "../../../../apis/AdvisorsAPI";
import { responseError } from "../../../../helpers";
import { setSnackbar } from "../../../../actions/snackbar.actions";

const ButtonChangeLegacyToAdoption = ({company, isButton=true}) => {
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [isLegacy, setIsLegacy] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(company){
      setIsLegacy(company?.uploadModel?.type === "legacy");
    }
  },[company]);

  const handleChangeUploadModel = async() => {
    setIsLoading(true)

    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure to change Upload Model Type to "Adoption", This company is Legacy and this action can't be undone`);

    try {
      if(!confirmation){
        // eslint-disable-next-line no-throw-literal
        throw { message: "Canceled by user" }
      }
      const {data} = await advisorAPI.put(`/companies/${company?._id}/change-legacy-to-adoption`);

      dispatch(fetchCurrentCompany(company._id));
      dispatch(fetchCurrentCompanyCredits(company._id, 'all'));
      dispatch(setSnackbar({message: data.message || "Company changed to Adoption"}))
    } catch (error) {
      responseError(error, undefined, "ButtonChangeLegacyToAdoption.handleChangeUploadModel", dispatch, 10000);
    }
    setIsLoading(false)
  }

  if (!me.permissions.includes("companies:convert_legacy_to_adoption")) {
    return <div></div>;
  }

  return <>
    {isLegacy && (
      <>
        {isButton ? (
          <Button color="orange" onClick={() => handleChangeUploadModel()} loading={isLoading} disabled={isLoading}>
            Change Upload Model to Adoption
          </Button>
        ) : (
          <div color="orange" onClick={() => handleChangeUploadModel()}>
            <Icon name="exchange" /> {!isLoading ? `Change Upload Model to ${"Adoption"}` : `Changing, please wait...`}
          </div>
        )}
      </>
    )}
  </>
}

export default ButtonChangeLegacyToAdoption