/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Icon, Pagination, Container, Table, Input, Dropdown, Loader, Segment, Dimmer, Popup } from 'semantic-ui-react'
//import { AddAdvisorModal } from '../components/modal/AddAdvisorModal';
import { TabContainer } from '../components/shared/TabContainer';
import { BetaUsersModal } from "../components/modal/BetaUsersModal";
import { prettyDate } from "../helpers/moment-helpers";
import { useSelector } from "react-redux";
import MessageTablesEmpty from "../components/globals/MessageTablesEmpty";
import { copyToClipboard } from "../helpers";

export const DashboardAdvisorsView = () => {
    const me = useSelector((state) => state.me);

    const pageSize = 15;
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalAdvisors, setTotalAdvisors] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    // const [company, setCompany] = useState('');
    const [includeCompanies, setIncludeCompanies] = useState([]);
    const [excludeCompanies, setExcludeCompanies] = useState([]);
    const [license, setlicense] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [businessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);
    const [businessCategory, setBusinessCategory] = useState('');
    // const [search, setSearch] = useState({activePage: 1, company: '', searchInput: '', license: ''});
    const [search, setSearch] = useState({activePage: 1, includeCompanies: '', excludeCompanies: '', searchInput: '', license: ''});

    //const [addAdvisorModalState, setAddAdvisorModalState] = useState(false);
    const [showBetaUsersModal, setShowBetaUsersModal] = useState(false);
    const [counterSearched, setCounterSearched] = useState(-1)

    const [sort, setSort] = useState('');
    const [sortType, setSortType] = useState('ASC');
    const [sortChange, setSortChange] = useState(0);
    const [download, setDownload] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [filterButtonIsDisabled, setFilterButtonIsDisabled] = useState(true)


  const [showTabActions, setShowTabActions] = useState(false);
  const [isCopied, setIsCopied] = useState(false)

    const licenseList = [
        {text: '', value: '', key: -1},
        {text: 'All', value: 'All', key: 0},
        {text: 'Trial', value: 'Trial', key: 1},
        {text: 'Licensed/Active', value: 'Purchased', key: 2},
        {text: 'Other', value: 'Other', key: 3},
        // {text: 'Purchased license', value: 'purchasedLicense', key: 1},
        // {text: 'On Free Trial', value: 'onFreeTrial', key: 2},
        // {text: 'With Current Free Trial', value: 'currentFreeTrial', key: 3},
        // {text: 'With Expired Free Trial', value: 'expiredFreeTrial', key: 4}
    ];

    const apiCall = async () => {
        setIsLoading(true)
        try {
            const response = await sysAPI.get(`/advisors`, {
                params:{
                    page: search.activePage,
                    size: pageSize,
                    query: searchInput,
                    include: `${includeCompanies}`,
                    exclude: `${excludeCompanies}`,
                    license: license,
                    sort: sort,
                    sorttype: sortType,
                    businesscategory: businessCategory,
                }
            })
            const { advisorsList, totalPages, total } = response.data;
            // console.log(totalPages);
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
            setTotalAdvisors(total);
            setCounterSearched(counterSearched+1)
        } catch (error) {
            console.log({error})
        }
        setIsLoading(false);
        setDownload(false);

        /* sysAPI.get(`/advisors?page=${search.activePage}&size=${pageSize}&query=${searchInput}&include=${includeCompanies}&exclude=${excludeCompanies}&license=${license}&sort=${sort}&sorttype=${sortType}&businesscategory=${businessCategory}`).then(response => {
            const { advisorsList, totalPages, total } = response.data;
            // console.log(totalPages);
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
            setTotalAdvisors(total);
            setIsLoading(false);
            setDownload(false);
        }).catch(err => {
            console.error(err);
        }); */
    }

    const exportCall = async () => {
        setDownload(true);
        //const url = `/advisors/export?query=${searchInput}&include=${includeCompanies}&exclude=${excludeCompanies}&license=${license}&sort=${sort}&sorttype=${sortType}&businesscategory=${businessCategory}`;
        sysAPI.get(`/advisors/export`,{
            params:{
                query: searchInput,
                include: `${includeCompanies}`,
                exclude: `${excludeCompanies}`,
                license: license,
                sort: sort,
                sorttype: sortType,
                businesscategory: businessCategory,
            },
            responseType: "blob"
         }).then(response => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");

            link.href = href;
            link.setAttribute('download', `Advisor List ${moment().format('MM-DD-YYYY hh.mm.ss')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.removeObjectURL(href);
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
            setDownload(false);
        }).finally(() => {
            setDownload(false);
        });
    }

    useEffect(() => {
        setBusinessCategoriesOptions([
            {
                value: '',
                text: '',
            },
            {
                value: 'implementation',
                text: 'Implementation',
            },
            {
                value: 'adoption',
                text: 'Adoption',
            },
            {
                value: 'growth',
                text: 'Growth',
            },
            {
                value: 'maintenance',
                text: 'Maintenance',
            },
        ]);

        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map((company, ind) => ({ key: ind, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: 'All' })
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });
        setCounterSearched(counterSearched+1)
        //apiCall();
    }, []);

    useEffect(() => {
        if(counterSearched > -1 && !download){
            apiCall()
        }
        if(download){
            exportCall()
        }
    },[search])

    useEffect(() => {
        const hasPermissionsToShowActions = me?.permissions?.filter(p => ["advisors:copy_email", "advisors:copy_id"].includes(p)) || []
        if(hasPermissionsToShowActions.length > 0){
            setShowTabActions(true)
        }
    },[me])

    const pageChange = (e, { activePage }) => {
        setSearch({...search, activePage});
    }

    const searchBtn_Click = () => {
        if(filterButtonIsDisabled){
            alert("not enough params to search");
            return
        }
        // setSearch({activePage: 1, company, searchInput, license});
        setSearch({activePage: 1, includeCompanies, excludeCompanies, searchInput, license});
    }


    const exportBtn_Click = () => {
        setDownload(true)
        setSearch({activePage: 1, includeCompanies, excludeCompanies, searchInput, license});
    }

    const dateFormatting = (date, hour = true) => {
        return date && moment(date).isValid()
            ? hour
                ? moment(date).format('MM/DD/YYYY HH:mm:ss')
                : moment(date).format('MM/DD/YYYY')
            : 'N/A';
    }

    const HeaderOptions = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {me?.permissions?.includes("advisors:add_beta_advisor") &&
                    <Button onClick={() => setShowBetaUsersModal(true)}>Beta Users</Button>
                }
                {/* {me?.permissions?.includes("advisors:add_advisor") &&
                    <Button icon onClick={() => setAddAdvisorModalState(true)}>
                        <Icon name='add' />
                    </Button>
                } */}
            </div>
        )
    }

    const sortColumn = (column) => {
        if (column === sort) {
            setSortType(sortType === 'ASC' ? 'DESC' : 'ASC');
            setSortChange(sortChange + 1)
            return
        }

        setSort(column);
        setSortType('ASC');
        setSortChange(sortChange + 1)
    }

    useEffect(() => {
        sortChange > 0 && apiCall();
    }, [sortChange]);

    useEffect(() => {
        if(
            includeCompanies.length <= 0 &&
            excludeCompanies.length <= 0 &&
            license.length <= 0 &&
            businessCategory.length <= 0 &&
            searchInput.length <= 2
        ){
            setFilterButtonIsDisabled(true)
        }else{
            setFilterButtonIsDisabled(false)
        }
    },[
        searchInput,
        includeCompanies,
        excludeCompanies,
        license,
        businessCategory,
    ])

    const TableBodyRow = ({advisor}) => {
        return (
            <Table.Row  style={{ color: advisor.hasActiveSession ? 'green' : 'black' }}>
                <Table.Cell>
                    <Link style={{ color: advisor.hasActiveSession ? 'green' : 'black' }} to={`/dashboard/advisors/${advisor._id}`}>{advisor.fullName}</Link>
                </Table.Cell>
                <Table.Cell>{advisor.email}</Table.Cell>
                <Table.Cell>
                    <Link style={{ color: advisor.hasActiveSession ? 'green' : 'black' }} to={`/dashboard/companies/${advisor.companyId}`}>{advisor.nameCompany}</Link>
                </Table.Cell>
                <Table.Cell>{advisor.poc}</Table.Cell>
                <Table.Cell>{advisor.status}</Table.Cell>
                <Table.Cell>{businessCategoriesOptions.find(e => e.value === advisor.businessCategory)?.text}</Table.Cell>
                <Table.Cell textAlign="right">{advisor.totalClients}</Table.Cell>
                {/* <Table.Cell textAlign="right">{advisor.totalExtractions}</Table.Cell> */}
                <Table.Cell positive={advisor.hasEnabled2FA}>{advisor.hasEnabled2FA ? 'Active' : 'Inactive'}</Table.Cell>
                <Table.Cell textAlign="right">{dateFormatting(advisor.licenseExpiresIn)}</Table.Cell>
                <Table.Cell textAlign="right">{dateFormatting(advisor.renewalDate, false)}</Table.Cell>
                <Table.Cell textAlign="right" style={{maxWidth: '100px'}}>{prettyDate({date: advisor.activity?.lastLogin || advisor.lastLogin, format: 'MM-DD-YYYY'})}</Table.Cell>
                <Table.Cell textAlign="right">{advisor.howManyLogins}</Table.Cell>
                {showTabActions && <>
                    <Table.Cell>
                        {me?.permissions?.includes("advisors:copy_email") && <>
                            <Popup
                                on={['hover']}
                                trigger={
                                    <Icon name='at' style={{cursor:"pointer"}} size="large" onClick={() => {
                                        copyToClipboard(`${advisor.email}`, false)
                                        setIsCopied(true)
                                        setTimeout(() => {
                                            setIsCopied(false)
                                        }, 1000)
                                    }} />
                                }
                                content={isCopied ? 'Copied': 'Click to copy Email'}
                                inverted
                            />
                        </>}
                        {me?.permissions?.includes("advisors:copy_id") && <>
                            <Popup
                                on='hover'
                                trigger={
                                    <Icon name='barcode' style={{cursor:"pointer"}} size="large" onClick={() => {
                                        copyToClipboard(`${advisor._id}`, false)
                                        setIsCopied(true)
                                        setTimeout(() => {
                                            setIsCopied(false)
                                        }, 1000)
                                    }} />
                                }
                                content={isCopied ? 'Copied': 'Click to copy ID'}
                                inverted
                            />
                        </>}
                    </Table.Cell>
                </>}
            </Table.Row>
        )
    }
    return (
        <DashboardTemplate scope='advisors' permission="advisors:advisor_view">
            <TabContainer
                title='Advisors Management'
                subtitle='CRUD of Advisors'
                headerOptions={<HeaderOptions />}
            >
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        gridTemplateRows: 'auto auto',
                        gridTemplateAreas:`
                            "inputs1 button1"
                            "inputs2 button2"`,
                        gap: '12px 12px',
                    }}
                >
                    <div>
                        <Input
                            value={searchInput}
                            onChange={e => setSearchInput(e.currentTarget.value)}
                            icon='search'
                            iconPosition='left'
                            onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                            fluid
                        />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '12px', gridArea: 'inputs2' }}>
                        <Dropdown placeholder='Include Companies' multiple selection search options={companiesList} value={includeCompanies} onChange={(e, { value }) => {setIncludeCompanies(value)}} />
                        <Dropdown placeholder='Exclude Companies' multiple selection search options={companiesList} value={excludeCompanies} onChange={(e, { value }) => {setExcludeCompanies(value)}} />
                        <Dropdown placeholder='License Type' selection search options={licenseList} value={license} onChange={(e, { value }) => {setlicense(value)}} />
                        <Dropdown placeholder='Business Categories' selection options={businessCategoriesOptions} value={businessCategory} onChange={(e, { value }) => {setBusinessCategory(value)}} />
                    </div>
                    <Button style={{ gridArea: 'button1' }} onClick={searchBtn_Click} disabled={isLoading || filterButtonIsDisabled} loading={isLoading}>Filter</Button>
                    {me?.permissions?.includes("advisors:download_excel_query") &&
                        <Button style={{ gridArea: 'button2' }} onClick={exportBtn_Click} disabled={download || isLoading || filterButtonIsDisabled} loading={download}>Download</Button>
                    }
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Full Name</Table.HeaderCell>
                            <Table.HeaderCell style={{maxWidth: '200px'}}>Email</Table.HeaderCell>
                            <Table.HeaderCell
                                className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                                onClick={() => sortColumn('company')}
                                style={{minWidth: '115px'}}
                            >
                                Company
                                {(sort === 'company' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'company' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'company' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                className={`table-header__order ${sort === 'poc' ? 'active' : ''}`}
                                onClick={() => sortColumn('poc')}
                            >
                                POC
                                {(sort === 'poc' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'poc' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'poc' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Business Category</Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'clients' ? 'active' : ''}`}
                                onClick={() => sortColumn('clients')}
                                style={{minWidth: '90px'}}
                            >
                                Clients
                                {(sort === 'clients' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'clients' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'clients' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'extractions' ? 'active' : ''}`}
                                onClick={() => sortColumn('extractions')}
                                style={{minWidth: '115px'}}
                            >
                                Extractions
                                {(sort === 'extractions' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'extractions' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'extractions' && <Icon name="sort" />}
                            </Table.HeaderCell> */}
                            <Table.HeaderCell>2FA</Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'expires' ? 'active' : ''}`}
                                onClick={() => sortColumn('expires')}
                            >
                                License Expires
                                {(sort === 'expires' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'expires' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'expires' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'renewal' ? 'active' : ''}`}
                                onClick={() => sortColumn('renewal')}
                            >
                                Renewal Date
                                {(sort === 'renewal' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'renewal' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'renewal' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'lastLogin' ? 'active' : ''}`}
                                onClick={() => sortColumn('lastLogin')}
                            >
                                Last Login
                                {(sort === 'lastLogin' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'lastLogin' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'lastLogin' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right"># Logins</Table.HeaderCell>
                            {showTabActions && <>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoading && <Table.Row>
                            <Table.Cell colSpan={13}>
                                <Segment style={{ width: '100%', height: '300px' }}>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                </Segment>
                            </Table.Cell>
                        </Table.Row>}
                        {!isLoading && advisorsList?.map((advisor, index) => (
                            <TableBodyRow key={index} advisor={advisor} />
                        ))}
                    </Table.Body>
                </Table>
                {!isLoading && advisorsList.length < 1 &&  <MessageTablesEmpty counterSearched={counterSearched} />}
                <Container
                    fluid
                    textAlign='right'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: '32px',
                    }}
                >
                    <div style={{ fontSize: '1.2em' }}>Total Advisors found: {totalAdvisors}</div>
                    <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} />
                </Container>
            </TabContainer>
            {/* <AddAdvisorModal
                modalState={addAdvisorModalState}
                setModalState={value => setAddAdvisorModalState(value)}
            /> */}
            <BetaUsersModal open={showBetaUsersModal} onClose={() => setShowBetaUsersModal(false)} />
        </DashboardTemplate>
    )
}