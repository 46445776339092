/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { advisorAPI } from '../../../../apis/AdvisorsAPI';
import { useDispatch, useSelector } from 'react-redux';
import enterprisePermissionsDefault from './AllPermissions';
import FormEnterprisePermissions from './FormEnterprisePermissions';
import { responseError } from '../../../../helpers';
import { setSnackbar } from '../../../../actions/snackbar.actions';
import PasswordConfirmationModal from '../../../modal/PasswordModal';

const EnterprisePermissions = () => {
  const dispatch = useDispatch();
  const [enterprisePermissions, setEnterprisePermissions] = useState(null);
  const [validationToken, setValidationToken] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [permissionsActiveInTheOffice, setPermissionsActiveInTheOffice] = useState(null);
  const companyProfile = useSelector((state) => state.currentCompany);

  const getInfo = async () => {
    //setLoading(true);
    try {
      const auxPermissions = [];
      const { data } = await advisorAPI.get(`/companies/${companyProfile._id}/permissions-enterprise`);
      for (const key in data.enterprisePermissions) {
        for (const field of data.enterprisePermissions[key]?.fields || []) {
          auxPermissions.push(`${key}:${field}`);
        }
      }
      setEnterprisePermissions(auxPermissions);

      if(data.enterprisePermissionsActivesInOffice){
        setPermissionsActiveInTheOffice(data.enterprisePermissionsActivesInOffice)
      }
    } catch (error) {
      responseError(error, undefined, "EnterprisePermissions.getInfo", dispatch);
    }
    setLoading(false);
  };

  const savePermissions = async (token) => {
    setLoadingSave(true);
    try {
      const auxPermissions = {};
      for (const per of enterprisePermissionsDefault) {
        if (!auxPermissions[per.key]) {
          auxPermissions[per.key] = {
            fields: [],
            isActive: false,
          };
        }
      }
      for (const per of enterprisePermissions) {
        const [key, field] = per.split(':');
        if (auxPermissions[key]) {
          auxPermissions[key].isActive = true;
          auxPermissions[key].fields.push(field);
        } else {
          console.warn(`key not found => ${key}`)
        }
      }
      const {data} = await advisorAPI.put(
        `/companies/${companyProfile._id}/permissions-enterprise`, 
        {enterprisePermissions: auxPermissions},
        { headers: {'Validation-Token': token}}
      );
      await getInfo()
      dispatch(setSnackbar({message: data.message || 'Success'}));
    } catch (error) {
      responseError(error, undefined, "EnterprisePermissions.savePermissions", dispatch);
    }
    setLoadingSave(false);
  };

  const sendValidationToken = (val) => {
    setValidationToken(val);
    savePermissions(val)
  }

  useEffect(() => {
    if (companyProfile?._id) {
      getInfo();
    }

    // eslint-disable-next-line
  }, [companyProfile?._id]);

  const Header = () => {
    return (
      <div>
        <h2>Enteprise Permissions</h2>
      </div>
    );
  };

  if (loading) {
    return (
      <>
        <Header />
        <Icon loading name="asterisk" /> Loading ...
      </>
    );
  }

  if (!enterprisePermissions) {
    return (
      <>
        <Header />
        Something was wrong...
      </>
    );
  }
  return (
    <>
      <Header />
      <div>
        <FormEnterprisePermissions
          permissions={enterprisePermissions}
          setPermissions={setEnterprisePermissions}
          defaultPermissions={enterprisePermissionsDefault}
          permissionsActiveInTheOffice={permissionsActiveInTheOffice}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button loading={loadingSave} disabled={loadingSave} color="green" onClick={() =>{ setOpenModalConfirm(true)}}>
        Save changes
        </Button>
      </div>
      <PasswordConfirmationModal
        setValidationToken={(val) => sendValidationToken(val)}
        modalState={openModalConfirm}
        setModalState={(val) => setOpenModalConfirm(val)}
      />
    </>
    
  );
};

export default EnterprisePermissions;
